@import "src/styles/variables";

.my-search-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  &--placeholder {
    color: $dark-grey !important;
  }

  &--no-label {
    margin-top: 0 !important;
  }

  &__field {
    width: 100%;
    padding: 8px 14px;
    background: $light-grey;
    border: 1px solid $dark-grey;
    border-radius: 4px;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    min-width: 0;
    margin-top: 8px;
  }
}

@media only screen and (min-width: $sb-tablet) {}