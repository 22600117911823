@import "src/styles/variables";

@mixin button {
  background: none;
  border: none;
  cursor: pointer;
}

@mixin big-button {
  @include button;
  display: none;

  @media only screen and (min-width: $sb-tablet) {
    display: block;
  }
}

@mixin big-icon {
  width: 17.5px;
  height: 35px;
}

$image-desktop-size: 840px;

.my-gallery {
  display: flex;
  flex-direction: column;

  &-frame {
    &--clickable {
      cursor: pointer;
    }
  }

  &-slider {
    overflow: hidden;
    width: 100vw;
  }

  &-photos {
    transition: transform 0.5s ease-out;
    display: flex;
    align-items: flex-start;
    height: 100vw;

    &__img {
      min-width: 100%;
      height: 100%;
      object-fit: contain
    }
  }

  &-actions {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 80px;
  }

  &__button {
    @include button;
    padding: 2px 25px;
  }

  &__prev {
    @include big-button;
    margin-right: 80px;

    & img {
      @include big-icon;
    }
  }

  &__next {
    @include big-button;
    margin-left: 80px;

    & img {
      @include big-icon;
    }
  }
}

@media only screen and (min-width: $sb-tablet) {
  .my-gallery {
    margin-bottom: 115px;
    flex-direction: row;

    &-frame {
      padding: 20px;
      border: 2px solid $transparent-blue-3;
      width: $image-desktop-size;
    }

    &-slider {
      width: 100%;
    }

    &-photos {
      height: $image-desktop-size - 240px;
    }

    &-actions {
      display: none;
    }
  }
}
