@import "variables";

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, Roboto, sans-serif, serif;
  font-size: 14px;
}

ol,
ul {
  padding-left: 0;
  list-style: none;
}

a,
a:active {
  color: inherit;
  text-decoration: none;
}

img {
  vertical-align: middle;
}