@import "src/styles/variables";

.my-search-radio-group {
  display: flex;
  flex-direction: row;
  gap: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
  }
}

@media only screen and (min-width: $sb-tablet) {}