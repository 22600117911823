@import "src/styles/variables";

.my-button {
  border: none;
  font-family: "Open Sans", sans-serif;
  color: $light-grey;
  background-color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 16px;
  box-shadow: 0 0 5px rgba(7, 33, 92, 0.1);
  border-radius: 8px;
  cursor: pointer;
  width: fit-content;
}
