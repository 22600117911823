@import "src/styles/variables";

@mixin button {
  background: none;
  border: none;
  cursor: pointer;
}

@mixin big-button {
  @include button;
  display: none;

  @media only screen and (min-width: $sb-tablet) {
    display: block;
    padding: 0;
  }
}

@mixin big-icon {
  width: 17.5px;
  height: 35px;
}

.my-home {
  display: flex;
  width: 100%;
  flex-direction: column;

  &-banner {
    &__mobile {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    &__desktop {
      display: none;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    padding: 32px 16px 32px;

    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 24px;
      color: $dark-blue;
      align-self: center;
      padding: 16px;
    }
  }

  &-featured {
    display: flex;
    flex-direction: column;
    background-color: $transparent-blue-4;
    margin-top: 36px;
    padding: 24px 16px;

    &--no-results {
      display: none;
    }

    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 24px;
      color: $dark-blue;
      align-self: center;
    }

    &-items {
      max-width: calc(100vw - 32px);
      overflow-x: hidden;
    }

    &-results {
      display: flex;
      flex-direction: column;
      gap: 16px;
      grid-template-columns: repeat(30, calc(100vw - 32px));
      transition: transform 0.5s ease-out;
    }

    &-actions {
      width: 100%;
      /// Actions for slider has been hidden as slider is no longer shown for smaller screens
      display: none;
      // display: flex;
      justify-content: center;
      margin-top: 15px;
    }

    &__button {
      @include button;
      padding: 2px 25px;
    }

    &__prev {
      @include big-button;

      & img {
        @include big-icon;
      }
    }

    &__next {
      @include big-button;

      & img {
        @include big-icon;
      }
    }
  }

  &-sorting {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    &__field {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      & :last-child {
        flex-grow: 1;
      }
    }

    &__label {
      color: $dark-blue;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }

    & input {
      margin-top: 0;
    }
  }

  &-category {
    padding-bottom: 24px;
    border-bottom: 1px solid $lighter-blue;
    margin-bottom: 15px;
  }

  &__total-count {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #747987;
  }

  &-paginator {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 24px 0;
  }

  &-results {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-footer {
    display: flex;
    flex-direction: column;
    background-color: $lighter-blue;
    gap: 66px;
    padding-bottom: 64px;
    font-size: 18px;
    font-weight: 600;
    align-items: center;
    color: $dark-blue;

    &__title {
      margin: 42px 16px 0;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 66px;
    }

    &__section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: auto;
      gap: 22px;
    }

    &__icon {
      fill: $dark-blue;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $dark-blue;
    margin-bottom: 28px;
    align-self: flex-end;
  }
}

@media only screen and (min-width: $sb-tablet) {
  .my-home {
    &-container {
      display: flex;
      flex-direction: column;

      &-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & label {
          text-align: start;
        }

        & button {
          border: none;
          cursor: pointer;
          background: $dark-grey;
          border-radius: 4px;
          padding: 8px;
        }
      }

      &-middle {
        display: grid;
        align-items: end;
        grid-template-columns: repeat(5, 2fr);
        grid-gap: 16px;
        margin-top: 20px;

        & input {
          min-width: 0;
          padding: 8px 14px;
          background: $light-grey;
          border: 1px solid $dark-grey;
          border-radius: 4px;
        }
      }

      &-bottom {
        display: grid;
        align-items: end;
        grid-template-columns: repeat(5, 2fr);
        grid-gap: 16px;
        margin-top: 20px;

        & input {
          min-width: 0;
          padding: 8px 14px;
          background: $light-grey;
          border: 1px solid $dark-grey;
          border-radius: 4px;
        }
      }
    }

    &-content {
      padding: 40px 120px 52px;

      &__title {
        display: none;
      }
    }

    &-featured {
      padding: 36px 0 28px;

      &__title {
        font-size: 24px;
        line-height: 33px;
      }

      &-content {
        display: flex;
        flex-direction: row;
        align-self: center;
        gap: 32px;
      }

      &-results {
        display: grid;
        max-width: calc(60vw + 16px * 2);
        grid-template-columns: repeat(30, calc(20vw));
        gap: 16px;
      }
    }

    &__category {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #747987;
    }

    &-sorting {
      margin-top: 32px;

      &__field {

        & :last-child {
          flex-grow: 0;
        }
      }
    }

    &__total-count {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #747987;
      flex-shrink: 0;
      margin-right: 16px;
      padding-right: 16px;
      border-right: 1px solid #D9D9D9;
    }

    &-results {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      flex-wrap: wrap;
    }

    &-footer {
      &__title {
        margin: 42px 16px 0;
        font-size: 24px;
      }

      &-content {
        display: flex;
        flex-direction: row;
        gap: 132px;
      }

      &__section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        gap: 22px;
      }

      &__icon {
        fill: $dark-blue;
      }
    }
  }
}

@media only screen and (min-width: $sb-laptop) {
  .my-home {
    &-banner {
      &__mobile {
        display: none;
      }

      &__desktop {
        display: flex;
      }
    }

    &-content {

      &__title {
        display: none;
      }
    }

    &-featured {
      &-results {
        display: grid;
        max-width: calc(60vw + 32px * 2);
        grid-template-columns: repeat(30, calc(20vw));
        gap: 32px;
      }
    }

    &-filters {
      display: flex;
      align-items: center;
    }

    &-results {
      min-width: 0;
      min-height: 0;
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}