@import "src/styles/variables";

.my-boat-card {
  width: 100%;
  border: 1px solid $lighter-blue;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  background-color: $light-grey;

  &__featured {
    position: absolute;
    top: 8px;
    left: 8px;
    background: $lighter-blue;
    border-radius: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    padding: 4px 8px;
    border-radius: 16px;
    color: $blue;
  }

  &__picture {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  &-content {
    width: 100%;
    display: flex;
    gap: 8px;
    flex-direction: column;
    padding: 16px;
    color: $dark-blue;
  }

  &__title {
    margin: 0;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-item {
    display: flex;
    align-items: center;
    gap: 6px;

    &__text {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__icon {
      display: flex;
      align-items: center;
      width: 16px;
      justify-content: center;
    }
  }
}

@media only screen and (min-width: $sb-tablet) {
  .my-boat-card {
    &:hover {
      background-color: $lighter-grey;
      border-color: $light-blue;
    }

    &__picture {
      height: 220px;
    }
  }
}
