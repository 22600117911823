@import "src/styles/variables";

.my-header {
  padding: 25px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
  z-index: 1;

  &__links {
    display: none;
  }

  &__logo {
    height: 80px;
  }

  &__menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
}

@media only screen and (min-width: $sb-tablet) {
  .my-header {
    padding: 70px 120px 70px 90px;

    &__logo {
      height: 140px;
    }
  }
}

@media only screen and (min-width: $sb-laptop) {
  .my-header {
    &__links {
      display: flex;
      margin-top: 80px;
      color: $light-grey;

      >* {
        font-family: "Open Sans", sans-serif;
        margin-left: 40px;
        font-size: 18px;
        font-weight: 600;
      }
    }

    &__logo {
      height: 160px;
    }

    &__menu {
      display: none;
    }
  }
}

@media only screen and (min-width: $sb-tablet) and (max-height: 725px) {
  .my-header {
    padding: 20px 120px 20px 90px;
  }
}