@import "../../styles/variables";

.my-top-drawer {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $transparent-blue;
  width: 100%;
  height: 0;
  transition: all 0.5s ease;
  overflow: hidden;
  color: $light-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  padding: 0;

  &--active {
    height: 100vh;
    padding: 25px 0;
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border-bottom: 1px solid $blue;
    padding: 0 22px;
  }

  &__content {
    overflow-y: scroll;
  }

  &__close {
    height: 80px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &-list {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &__link {
      margin-bottom: 50px;

      a {
        font-size: 18px;
        font-weight: 600;
        color: $light-grey;
      }
    }
  }
}

@media only screen and (min-width: $sb-laptop) {
  .my-top-drawer {
    display: none;
  }
}