@import "src/styles/variables";

.my-search-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  &-fields {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  & input {
    width: 100%;
    padding: 8px 14px;
    background: $light-grey;
    border: 1px solid $dark-grey;
    border-radius: 4px;
    min-width: 0;
    margin-top: 8px;

    &::placeholder {
      color: $dark-grey;
    }
  }
}

@media only screen and (min-width: $sb-tablet) {}