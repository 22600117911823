@import "src/styles/variables";

.my-contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 38px;

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-fields {
    display: flex;
    flex-direction: column;
    padding-bottom: 17px;

    &__placeholder {
      color: $grey  !important;
    }

    & input,
    & select {
      font-family: "Open Sans", sans-serif;
      padding: 16px 0 16px 20px;
      background: #FBFBFB;
      border: 1px solid #A6AEC1;
      border-radius: 8px;
      margin-bottom: 23px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      color: $dark-blue;
      caret-color: blue;

      -moz-appearance: none;
      /* Firefox */
      -webkit-appearance: none;
      /* Safari and Chrome */
      appearance: none;

      &::placeholder {
        color: $grey;
      }
    }

    & textarea {
      font-family: "Open Sans", sans-serif;
      padding: 16px 0 16px 20px;
      background: #FBFBFB;
      border: 1px solid #A6AEC1;
      border-radius: 8px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      resize: none;
      color: $dark-blue;
      caret-color: blue;

      &::placeholder {
        color: $grey;
      }
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    align-items: center;
  }

  &__message {
    margin-top: 17px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $dark-blue;
    padding: 2px 5px;
    border-radius: 4px;
  }

  &__submit {
    font-family: "Open Sans", sans-serif;
    border: none;
    background: $blue;
    color: $light-grey;
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    max-width: fit-content;

    &:disabled {
      background: $grey;
      cursor: not-allowed;
    }
  }
}



@media only screen and (min-width: $sb-tablet) {
  .my-contact-form {
    width: 100%;
    align-items: flex-start;

    &-fields {
      padding: 42px 0 0 0;

      & input,
      & select {
        font-size: 18px;
        line-height: 25px;
      }

      & textarea {
        font-size: 18px;
        line-height: 25px;
      }
    }

    &-actions {
      margin-top: 40px;
      align-items: flex-start;
    }

    &__message {
      font-size: 18px;
      line-height: 25px;
    }

    &__submit {
      margin-right: 36px;
    }
  }
}