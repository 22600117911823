@import "src/styles/variables";

.my-filters-form {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 15px;

  &__option {
    all: unset;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 8px;
    background: $lighter-blue;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $dark-blue;
  }

  &__icon {
    height: 16px;
    width: 16px;
    fill: $dark-blue;
  }


  &__clear {
    cursor: pointer;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $dark-blue;
    background-color: transparent;
  }
}

@media only screen and (min-width: $sb-tablet) {
  .my-filters-form {
    margin-top: 0;
  }
}