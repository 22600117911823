@import "src/styles/variables";

.my-boat-detail {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: $dark-blue;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  &__image {
    width: 100%;
    padding: 8px 0;
    border-top: 2px solid $lighter-blue;
    border-bottom: 2px solid $lighter-blue;
  }

  &-info {
    padding: 0 16px 12px;
  }

  &__title {
    margin-top: 40px;
    margin-bottom: 24px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
  }

  &-items {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

    &__text {
      margin: 0;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
  }

  &-specs {
    display: flex;
    flex-direction: column;
    background-color: $transparent-light-blue;
    padding: 36px 16px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 60px;
    margin-top: 24px;

    &__title {
      margin: 0;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 36px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &-section {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &__item {
      display: flex;
      gap: 8px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $dark-blue;
    margin: 32px 0;
    align-self: flex-start;
    padding: 0 16px;
  }

  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $transparent-light-blue;
  }
}

@media only screen and (min-width: $sb-tablet) {
  .my-boat-detail {
    font-size: 18px;
    line-height: 25px;

    &__link {
      padding-left: 120px;
    }

    &-head {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding-left: 120px;
      align-items: center;
    }

    &__image {
      padding: 8px 0 8px 8px;
      border: 2px solid $lighter-blue;
      border-right: none;
      width: 50vw;
    }

    &-info {
      padding: 0 0 12px;
    }

    &__title {
      margin: 0 0 27px;
      font-size: 48px;
      line-height: 65px;
      padding-right: 30px;
    }

    &-item {
      &__text {
        margin: 0;
      }
    }

    &-content {
      padding: 80px 120px;
    }

    &-specs {
      padding: 90px 120px;
      margin-bottom: 80px;
      margin-top: 24px;

      &__title {
        margin: 0;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 36px;
      }

      &-content {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__gallery {
      align-self: center;
    }
  }
}
