@import "src/styles/variables";

.my-footer {
  background-color: $dark-blue;
  padding: 90px 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 24px;
    margin-bottom: 28px;
    color: $light-grey;
  }

  &__subtitle {
    color: $lighter-blue;

    &:active {
      color: $lighter-blue;
    }
  }

  &-links {
    margin-bottom: 38px;

    >* {
      margin-bottom: 30px;
    }

    >*:last-child {
      margin-bottom: 0;
    }
  }

  &-link {
    display: flex;
    align-items: center;
    color: $lighter-blue;

    &:active {
      color: $lighter-blue;
    }

    &__icon {
      margin-right: 18px;
    }

    &__text {
      display: flex;
      flex-direction: column;
    }
  }

  &__social {
    margin-right: 22px;
  }

  &-endorsed {
    color: $lighter-blue;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
    width: 100px;

    &-logos {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
      margin-top: 16px;
    }
  }

  &__rights {
    font-weight: 400;
    margin-top: 26px;
    color: $lighter-blue;
  }
}

@media only screen and (min-width: $sb-tablet) {
  .my-footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 70px 40px;

    &-endorsed {
      gap: 30px;
      width: 100%;
      margin-bottom: 70px;
      font-size: 18px;
      white-space: nowrap;

      &-logos {
        flex-direction: row;
        height: 46px;
        margin-top: 0;
      }
    }

    &-link {
      font-size: 18px;
    }

    &-links {
      margin-bottom: 0;
    }

    &__title {
      font-size: 36px;
      line-height: 49px;
      margin: 0;
    }

    &__subtitle {
      font-size: 18px;
    }

    &__rights {
      margin-top: 26px;
      font-size: 18px;
    }
  }
}

@media only screen and (min-width: $sb-laptop) {
  .my-footer {
    padding: 93px 120px;
  }
}