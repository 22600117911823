@import "src/styles/variables";

.my-search-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 24px 0;
  text-align: center;
  height: 100%;
  overflow: hidden;

  &-submit {
    &__mobile {
      display: block;
      width: 100%;
      margin-top: 15px;
    }

    &__desktop {
      display: none;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    overflow: scroll;
  }

  &-container {

    &-top,
    &-middle,
    &-bottom {
      display: flex;
      flex-direction: column;
      gap: 17px;
    }
  }
}

@media only screen and (min-width: $sb-tablet) {
  .my-search-form {

    &-submit {
      &__mobile {
        display: none;
      }

      &__desktop {
        display: block;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;

      // max-width: 600px;
      &-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & label {
          text-align: start;
        }

        & button {
          border: none;
          cursor: pointer;
          background: $dark-grey;
          border-radius: 4px;
          padding: 8px;
        }
      }

      &-middle {
        display: grid;
        align-items: end;
        grid-template-columns: repeat(5, 2fr);
        grid-gap: 16px;
        margin-top: 20px;

        & input {
          min-width: 0;
          padding: 8px 14px;
          background: $light-grey;
          border: 1px solid $dark-grey;
          border-radius: 4px;
        }
      }

      &-bottom {
        display: grid;
        align-items: end;
        grid-template-columns: repeat(5, 2fr);
        grid-gap: 16px;
        margin-top: 20px;

        & input {
          min-width: 0;
          padding: 8px 14px;
          background: $light-grey;
          border: 1px solid $dark-grey;
          border-radius: 4px;
        }
      }
    }
  }
}