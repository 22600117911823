// Colors
$dark-blue: #07215C;
$transparent-blue: rgba(7, 33, 92, 0.95);
$transparent-blue-2: rgba(7, 33, 92, 0.6);
$transparent-blue-3: rgba(135, 167, 225, 0.3);
$transparent-blue-4: rgba(135, 167, 225, 0.2);
$transparent-dark-blue: rgba(4, 22, 63, 0.8);
$blue: #5C88D7;
$light-blue: #87A7E1;
$lighter-blue: #d2ddf3;
$transparent-light-blue: rgba(210, 221, 243, 0.3);
$transparent-light-blue-2: rgba(92, 136, 215, 0.3);
$yellow: #F7C559;
$transparent-yellow: rgba(247, 197, 89, 0.3);
$grey: #A6AEC1;
$dark-grey: #7B818F;
$light-grey: #FBFBFB;
$lighter-grey: #F3F6FC;
$white: #F8F8FF;

// Screen breakpoints
$sb-mobile: 480px;
$sb-tablet: 768px;
$sb-laptop: 1293px;
$sb-large: 1441px;