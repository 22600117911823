@import "src/styles/variables";

.my-banner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $dark-blue;
  color: $light-grey;
  width: 100%;

  &__hidden {
    height: 58px;
  }

  &--small {
    height: 70vh;
  }

  &--bottom {
    justify-content: flex-end;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: top;

    &--bottom {
      object-position: bottom;
    }

    &--center {
      object-position: center;
    }
  }

  &__arrows {
    margin: 20px 0;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    z-index: 0;
    padding: 0 25px;
    width: 100%;
    height: 100%;

    &--bottom {
      justify-content: flex-end;
    }

    &--center {
      justify-content: space-between;
    }

    >h1 {
      color: $light-grey;
      padding: 10vh 25px 0;
      font-size: 24px;
      font-weight: 600;
      margin: 0;
    }

    >h2 {
      font-size: 14px;
      font-weight: 400;
      margin: 35px 0;
    }
  }

  &-main {
    align-self: flex-start;

    >h1 {
      color: $light-grey;
      padding: 0 25px;
      font-size: 24px;
      line-height: 33px;
      font-weight: 600;
      margin: 0;
    }

    >h2 {
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
      margin: 35px 0;
    }
  }

  &-items {
    margin-top: 40px;
    min-height: 212px;
    padding: 0 28px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;

    &-container {
      width: 100%;
    }
  }

  &-item {
    color: $light-grey;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &__icon {
      margin-right: 14px;
    }
  }
}

@media only screen and (min-width: $sb-tablet) {
  .my-banner {

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0;
      margin-top: 20vh;

      >h1 {
        font-size: 48px;
        padding-top: 0;
      }

      >h2 {
        font-size: 14px;
        font-weight: 400;
        margin: 35px 0;
      }
    }

    &-main {
      border: 2px solid $transparent-light-blue;
      border-left: none;
      width: 64vw;
      padding: 40px 55px 40px 90px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      >h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 65px;
        margin: 0 0 35px;
        text-align: left;
        padding: 0;
      }

      >h2 {
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        text-align: left;
        margin: 0 0 35px;
      }
    }

    &-items {
      align-self: center;
      margin: 0;
      min-height: 187px;
      flex-direction: row;
      width: 80vw;
      align-items: center;
      justify-content: space-evenly;
      z-index: 1;

      &-container {
        position: relative;
        display: flex;
        justify-content: center;
        margin: 10vh 0;
        width: 100%;
        align-self: center;
      }

      &__border {
        background-color: $transparent-blue-2;
        position: absolute;
        left: 0;
        height: 120%;
        top: -10%;
        width: 90vw;
        border-left: none;
      }
    }

    &-item {
      font-size: 18px;
      flex-direction: column;
      align-items: center;
      width: 118px;

      &__icon {
        width: 60px;
        margin-right: 0;
        margin-bottom: 10px;
        max-height: none;
      }
    }

    &__arrows {
      top: calc(100% - 55px);

      &--extra {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: $sb-laptop) {
  .my-banner {

    &__title {
      position: relative;
      top: -50px;
      opacity: 0;
      transition: opacity 0.7s ease, top 0.5s ease;

      &--show {
        top: 0;
        opacity: 1;
      }
    }

    &-main {
      position: relative;
      left: -100px;
      opacity: 0;
      transition: opacity 0.7s ease, left 0.5s ease;

      &--show {
        left: 0;
        opacity: 1;
      }

      >h1 {
        font-size: 48px;
      }

      >h2 {
        font-size: 18px;
      }
    }

    &-items-container {
      position: relative;
      left: -100px;
      opacity: 0;
      transition: opacity 0.7s ease-out, left 0.5s ease-out;

      &--show {
        left: 0;
        opacity: 1;
      }
    }
  }
}

@media only screen and (min-width: $sb-tablet) and (max-height: 725px) {
  .my-banner {
    &-main {
      padding: 30px 55px 30px 90px;

      >h1 {
        font-size: 36px;
        line-height: 44px;
      }

      >h2 {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}