@import "src/styles/variables";

.my-dropdown {
  position: relative;

  &__underscore {
    width: 0;
    border-bottom: solid 2px $yellow;
    transition: width 0.2s ease-out;
  }

  &:hover {
    .my-dropdown__underscore{
      width: 100%;
    }
    .my-dropdown-content {
      display: flex;
    }
  }

  &-content {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background-color: $transparent-dark-blue;
    min-width: 200px;
    margin-top: 16px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    padding: 0 12px 16px;

    a {
      color: $light-grey;
      padding: 12px 0 0;
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
      display: block;
      text-align: center;

      &:hover {
        color: $light-blue;
      }
    }
  }
}
