@import "src/styles/variables";

.my-paginator {
  display: flex;
  gap: 10px;
  height: 24px;

  &__button {
    all: unset;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #7B818F;
    text-align: center;
    width: 24px;
    cursor: pointer;

    &--arrow {
      color: $dark-blue;
    }

    &--active {
      color: $dark-blue;
      background-color: $lighter-blue;
      border-radius: 500px;
    }
  }
}

@media only screen and (min-width: $sb-tablet) {
  .my-paginator {
    gap: 15px;
    height: 30px;

    &__button {
      width: 30px;
      font-size: 18px;
      line-height: 25px;
    }
  }
}