@import "src/styles/variables";

.my-category-form {
  display: flex;
  gap: 13px;
  margin-top: 4px;

  &--desktop {
    display: none;
  }

  & label {
    flex-grow: 1;
  }

  & button {
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background: #87a7e1;
  }
}

@media only screen and (min-width: $sb-tablet) {
  .my-category-form {
    &--mobile {
      display: none;
    }

    &--desktop {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 27px;
    }

    &__option {
      display: flex;
      height: 90px;
      width: 200px;
      border: 1px solid #7b818e99;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 23px;
      gap: 18px;
      color: #7B818F;
      background-color: white;
      line-height: 19px;
      font-size: 14px;
      cursor: pointer;
      flex-grow: 1;
      text-align: start;

      &__icon {
        width: 100px;
      }

      &--active {
        background-color: $lighter-blue;
        color: #07215C;
        border: 1px solid #5b86d799;
      }
    }
  }
}