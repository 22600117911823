@import "src/styles/variables";

.my-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 14px 17px 20px;
  margin-bottom: 50px;
  text-align: center;

  &__title {
    font-family: Junge, "sans-serif";
    font-size: 24px;
    line-height: 29px;
    color: $dark-blue;
    margin-bottom: 20px;
    text-transform: uppercase;

    &-accent {
      color: $blue;
    }
  }

  &__subtitle {
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    color: $grey;
    margin-bottom: 40px;
    width: 100%;
  }
}

@media only screen and (min-width: $sb-tablet) {
  .my-form-container {
    max-width: 600px;

    &__title {
      font-size: 42px;
      line-height: 50px;
      margin-bottom: 30px;
    }

    &__subtitle {
      font-size: 18px;
      line-height: 25px;
    }
  }
}